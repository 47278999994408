//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'
export default {
  name: 'PopoverContacts',
  components: {
    PopoverDefault: () => import('./PopoverDefault.vue'),
  },
  computed: {
    ...mapState({
      settings: state => state.settings.settings,
    }),
  },
  methods: {
    handleRoute() {
      this.$emit('click:close')
    },
  },
}
