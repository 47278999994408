//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'ReviewResult',
  props: {
    reviews: {
      type: Array,
      default: () => [],
      require: true,
    },
  },
  computed: {
    grades() {
      return this.reviews.reduce((a, b) => {
        if (!a[b.stars]) {
          a[b.stars] = [b.stars]
          return a
        }
        a[b.stars] = [...a[b.stars], b.stars]
        return a
      }, {})
    },
    average() {
      const sum = this.reviews.reduce((a, b) => a + Number(b.stars), 0)
      const average =  (sum / this.reviews.length).toFixed(1)
      return !isNaN(average) ? average : 0
    },
    stars() {
      return (this.average * 100) / 5
    },
    reviewsText() {
      return this.reviews.length === 1 ?
        this.$t('review').toLowerCase() :
        this.$t('reviews').toLowerCase()
    }
  },
  methods: {
    scaleWidth(array) {
      return (array.length * 100) / this.reviews.length
    },
  },
}
