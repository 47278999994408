//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import MainHeaderSection from '~/components/pages/Main/MainHeaderSection.vue'
import Slider from '~/components/UI/Slider.vue'
export default {
  name: 'CategoriesSlider',
  components: {
    Slider,
    CategoryMiniatureItem: () =>
      import('~/components/modules/Miniatures/CategoryMiniatureItem.vue'),
    MainHeaderSection,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    link: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      default: () => [],
    },
    linkName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      settings: {
        itemsToShow: 3,
        itemsToSlide: 3,
        breakpoints: {
          320: {
            itemsToShow: 1.25,
            itemsToSlide: 1,
          },
          576: {
            itemsToShow: 2.25,
            itemsToSlide: 2,
          },
          768: {
            itemsToShow: 3.25,
            itemsToSlide: 3,
          },
          1024: {
            itemsToShow: 4.25,
            itemsToSlide: 4,
          },
          1366: {
            itemsToShow: 4,
            itemsToSlide: 4,
          },
        },
      },
    }
  },
}
