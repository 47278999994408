//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import MainHeaderSection from '../../pages/Main/MainHeaderSection.vue'
import Slider from '~/components/UI/Slider.vue'
export default {
  name: 'RoomsSlider',
  components: {
    Slider,
    MainHeaderSection,
    RoomMiniatureItem: () =>
      import('~/components/modules/Miniatures/RoomMiniatureItem.vue'),
  },
  props: {
    items: {
      type: Array,
      default: () => [],
      require: true,
    },
    title: {
      type: String,
      default: '',
    },
    link: {
      type: String,
      default: '',
    },
    linkName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        breakpoints: {
          480: {
            itemsToShow: 1.52,
            itemsToSlide: 1,
          },
          768: {
            itemsToShow: 2.52,
            itemsToSlide: 2,
          },
          1024: {
            itemsToShow: 3.32,
            itemsToSlide: 3,
          },
          1366: {
            itemsToShow: 2.52,
            itemsToSlide: 2,
          },
          1440: {
            itemsToShow: 2.33,
            itemsToSlide: 2,
          },
        },
      },
    }
  },
}
