//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'
import { IS_TABLET } from '~/store/device/type.device'
export default {
  name: 'PopoverBasket',
  components: {
    PopoverDefault: () => import('./PopoverDefault.vue'),
    ProductMiniatureItem: () =>
      import('~/components/modules/Miniatures/ProductMiniatureItem.vue'),
  },
  computed: {
    ...mapState({
      products: state => state.cart.items,
    }),
    price() {
      let price = 0
      this.products.forEach((item) => (price += +item.price))
      return `${price.toFixed(2)} zl`
    },
    isTablet() {
      return this.$store.getters[`device/${IS_TABLET}`]
    },
  },
  async created() {
    await this.$store.dispatch('cart/FETCH_CART_PRODUCTS')
  },
  methods: {
    handleRoute() {
      this.$emit('click:close')
    },
  },
}
