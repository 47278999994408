//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ReviewItem from './ReviewItem.vue'
import ReviewsHead from './ReviewsHead.vue'
import Paginations from '~/components/UI/Paginations.vue'

export default {
  name: 'ReviewsModule',
  components: {
    ReviewsHead,
    ReviewItem,
    Paginations,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    itemInfo: {
      type: Object,
      default: () => ({}),
    },
    hideHead: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      reviews: [],
      total: 0,
      pages: 0,
      sort: {
        per_page: 3,
        page: 1,
      },
      selectPage: 1,
      settingsPagination: {
        counter: false,
        offset: false,
      },
      countItems: 0,
    }
  },
  fetch() {
    return this.fetchReviews()
  },

  mounted() {
    if (this.$route.query.toReviews) {
      setTimeout(() => {
        const element = document.getElementById('reviews-start')
        const top = element.offsetTop - 150
        window.scrollTo({
          behavior: 'smooth',
          top,
        })
      }, 500)
    }
  },

  methods: {
    onSelectPage(value) {
      this.sort.page = value
      this.fetchReviews()
    },
    selectOffset(value) {
      this.sort.per_page = value
    },
    async fetchReviews() {
      const type = /product/
      const patch = this.$route.path
      const slug = this.$route.params.id
      const typeReview = type.test(patch) ? 'product' : 'set'
      const payload = {
        per_page: this.sort.per_page,
        page: this.sort.page,
      }
      try {
        const response = await this.$api.reviews.getProductReviews(payload, slug, typeReview)
        const { data, meta } = response
        this.reviews = data
        this.total = meta.pagination.total
        this.pages = meta.pagination.total_pages
        this.countItems = meta.pagination.count
      } catch (error) {
        // console.log(error)
      }
    },
  },
}
