//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'PopoverContacts',
  components: {
    PopoverDefault: () => import('./PopoverDefault.vue'),
  },
  data() {
    return {}
  },

  computed: {
    availableLocales() {
      return this.$i18n.locales
      // return this.$i18n.locales.filter(i => i.code !== this.$i18n.locale)
    },
  },
  methods: {
    handleRoute() {
      this.$emit('click:close')
    },
  },
}
