//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import MainHeaderSection from '~/components/pages/Main/MainHeaderSection.vue'
import Slider from '~/components/UI/Slider.vue'
export default {
  name: 'ProductSlider',
  components: {
    Slider,
    MainHeaderSection,
    ProductItemCard: () =>
      import('~/components/modules/Cards/ProductItemCard.vue'),
  },
  props: {
    type: {
      type: Number,
      default: 1,
    },
    items: {
      type: Array,
      default: () => [],
      require: true,
    },
    entity: {
      type: String,
      default: 'product',
    },
    title: {
      type: String,
      default: '',
    },
    link: {
      type: String,
      default: '',
    },
    linkName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        breakpoints: {
          320: {
            itemsToShow: 1.25,
            itemsToSlide: 1,
          },
          480: {
            itemsToShow: 2.25,
            itemsToSlide: 2,
          },
          768: {
            itemsToShow: 3.25,
            itemsToSlide: 3,
          },
          1024: {
            itemsToShow: 4.35,
            itemsToSlide: 4,
          },
          1366: {
            itemsToShow: 4,
            itemsToSlide: 4,
          },
        },
      },
    }
  },
  methods: {
    onClick(value) {
      const { id, data } = value
      const keys = ['labels', 'status', 'name', 'price_type', 'slug']
      const slide = this.items.find((item) => item.id === id)
      for (const key of keys) {
        slide[key] = data[key]
      }
      const variations = Object.keys(data.variations).filter(
        (key) => key !== 'primary',
      )
      slide.variations = data.variations[
        data.variations.primary ? data.variations.primary : variations[0]
      ].map((variation) => ({
        ...variation,
        file: variation.files[0]?.url ? variation.files[0].url : '',
      }))
    },
  },
}
