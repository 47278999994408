//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { HAS_TOKEN } from '~/store/auth/types.auth'
export default {
  name: 'PopoverDefault',
  props: {
    w: {
      type: String,
      default: '360px',
    },
  },
  computed: {
    isAuth() {
      return this.$store.getters[`auth/${HAS_TOKEN}`]
    },
    styles() {
      return {
        ...(this.w && { width: this.w }),
      }
    },
  },

  methods: {
    handleAuth(type) {
      this.$root.$emit('handleAuthModal', type)
    },
  },
}
