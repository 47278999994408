//
//
//
//
//
//
//

import ReviewFeedback from './ReviewFeedback.vue'
import ReviewResult from './ReviewResult.vue'
export default {
  name: 'ReviewsHead',
  components: { ReviewFeedback, ReviewResult },
  props: {
    reviews: {
      type: Array,
      default: () => [],
      require: true,
    },
    itemInfo: {
      type: Object,
      default: () => ({}),
    },
  },
}
