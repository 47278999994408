//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { HAS_TOKEN } from '~/store/auth/types.auth'
import AddReviewModal from '~/components/modules/ModalWindows/profile/reviews/AddReviewModal.vue'
export default {
  name: 'ReviewFeedback',
  components: {
    AddReviewModal,
  },
  props: {
    itemInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    isAuth() {
      return this.$store.getters[`auth/${HAS_TOKEN}`]
    },
  },
  methods: {
    onClick() {
      const type = /product/
      const patch = this.$route.path
      const entity = this.$props.entity || (type.test(patch) ? 'product' : 'set')
      const item = {
        ...this.$props.itemInfo,
        entity
      }
      this.$refs.addReviewModal.onOpen(item)
    },
    handleAuth(type) {
      this.$root.$emit('handleAuthModal', type)
    },
  },
}
