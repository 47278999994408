//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Rating from '~/components/UI/Rating.vue'
export default {
  name: 'ReviewItem',
  components: { Rating },
  props: {
    review: {
      type: Object,
      default: () => ({}),
      require: true,
    },
  },
}
