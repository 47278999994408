//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'
export default {
  name: 'PopoverFavorites',
  components: {
    PopoverDefault: () => import('./PopoverDefault.vue'),
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapState({
      favorites: state => state.favorites.items,
    }),
  },
  async created() {
    await this.$store.dispatch('favorites/FETCH_FAVORITES')
  },
  methods: {
    handleRoute() {
      this.$emit('click:close')
    },
  },
}
